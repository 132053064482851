import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";

/**
 * Component to fetch and display OS query data for a given server.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.servername - The name of the server to query.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <OsQuery servername="example-server" />
 *
 * @description
 * This component fetches OS query data from a backend service and displays it.
 * While the data is being fetched, it shows a loading skeleton. Once the data
 * is fetched, it displays various details about the server in a read-only format.
 * If no data is found, it displays a "No data found" message.
 */
function OsQuery({ servername }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(
      `https://prod-cmdbbackend.azurewebsites.net/getosquerydata?servername=${encodeURIComponent(
        servername
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      });
  }, [servername]);

  return (
    <div style={{ padding: "10px" }}>
      {loading ? (
        <div style={{width:"600px",display: "flex", justifyContent: "space-between",marginTop:"20px" }}>
           <div style={{ flex: 1, marginRight: "10px" }}>
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />

          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />

          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />

          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />

          <Skeleton variant="text" sx={{ fontSize: "2rem" }} animation="wave" />
          </div>

        </div>
      ) : data.message !== "No Details Found" ? (
        <div style={{ maxHeight: "300px", overflowY: "scroll",width:"fit-content",padding:" 0 20px" }}>
          <span style={{ marginBottom: "5px" }}>Specifications</span>
          <div style={{ display: "flex", justifyContent: "space-between",marginTop:"20px" }}>
            <div style={{ flex: 1, marginRight: "10px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px" }}>
                <p>Computer Name</p>
                <input
                  type="text"
                  value={data.computer_name}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Created At</p>
                <input
                  type="text"
                  value={data.created_at}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Host Name</p>
                <input
                  type="text"
                  value={data.hostname}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Platform</p>
                <input
                  type="text"
                  value={data.platform}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Uptime</p>
                <input
                  type="text"
                  value={data.uptime}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Memory</p>
                <input
                  type="text"
                  value={data.memory}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>CPU Type</p>
                <input
                  type="text"
                  value={data.cpu_type}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              
            </div>
            <div style={{ flex: 1 }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Status</p>
                <input
                  type="text"
                  value={data.status}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
             
             
             
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Primary IP</p>
                <input
                  type="text"
                  value={data.primary_ip}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Primary MAC</p>
                <input
                  type="text"
                  value={data.primary_mac}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Hardware Vendor</p>
                <input
                  type="text"
                  value={data.hardware_vendor}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Hardware Model</p>
                <input
                  type="text"
                  value={data.hardware_model}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>UUID</p>
                <input
                  type="text"
                  value={data.uuid}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "7px", marginBottom: "5px", alignItems: "center",width:"500px"  }}>
                <p>Seen Time</p>
                <input
                  type="text"
                  value={data.seen_time}
                  disabled
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    width: "350px",
                    backgroundColor: "rgba(211, 211, 211, 1)",
                    textAlign: "center",
                  }}
                />
              </div>
           
          
             
           
             
             
           
            </div>
          </div>
       
       
         
        </div>
      ) : (
        <p>No data found</p>
      )}
    </div>
  );
}

export default OsQuery;