import React, { useState,useEffect } from "react";
import { FaTimes } from "react-icons/fa";

/**
 * Popup component that displays a modal with a background overlay.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed inside the popup.
 * @param {function} [props.onClose] - Optional callback function to be called when the popup is closed.
 *
 * @example
 * <Popup onClose={() => console.log('Popup closed')}>
 *   <p>Your content here</p>
 * </Popup>
 */
const Popup = ({ children, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose(); // Call the provided onClose function if available
    }
  };

  const popupStyle = {
    position: "fixed",
    top: "10%", // Adjust vertical positioning as needed
     left:"2.5%",// Adjust horizontal positioning as needed
    width: "95%",
    height: "80%",
    backgroundColor: "white", // Customize background color
    borderRadius: 5, // Add some border radius for aesthetics
    
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Add a subtle shadow
    display: isOpen ? "block" : "none", // Control visibility based on isOpen state
    zIndex: 100, // Ensure the popup is on top of other elements
  };

  
  const backgroundOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: document.body.scrollHeight,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black for a grayed-out effect
    zIndex: 99, // Set a z-index slightly lower than the popup (100)
  };
  useEffect(() => {
    document
      .querySelectorAll('[rel="noopener noreferrer"]')
      ?.forEach(function (element) {
        element.style.display = "none";
        // console.log("Tree table5", table5);
        var fit = document.getElementsByClassName(
          "react-flow__controls-button react-flow__controls-fitview"
        );
        console.log("fit", fit);
        if (fit[0]) {
          console.log("fit", fit[0]);

          setInterval(fit[0].click(), 1000);
        }
        // if (reactFlowRef.current) {
        //   // reactFlowRef.current.;
        // }
      });
  }, []);



  return (
    <div style={backgroundOverlayStyle}>
      <div style={popupStyle}>
        {children} {/* Your popup content goes here */}
        
        <FaTimes
          className="close"
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            margin: "15px",
            cursor: "pointer",
          }}
          size={23}
          onClick={() => {
           handleClose();
          }}
        />
        
      </div>
    </div>
  );
};

export default Popup;
