import {
  ReactFlow,
  MiniMap,
  Controls,
  MarkerType,
  SimpleBezierEdge,
  Background,
} from "reactflow";
import { React, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { TableContext } from "../context";

import { AiOutlineExpandAlt } from "react-icons/ai";

import "reactflow/dist/style.css";
import Popup from "./Popup";

// const initialNodes = [
//   { id: '1', position: { x: 200, y: 0 }, table5: { label: 'gcss' }, },
//   { id: '2', position: { x: 600, y: 0 }, table5: { label: 'clue' } },
//   { id: '3', position: { x: 200, y: 200 }, table5: { label: 'sap' } },
//   { id: '4', position: { x: 600, y: 200 }, table5: { label: 'sap financial' } },
//   { id: '5', position: { x: 400, y: 100 }, table5: { label: 'MEPC' } },

// ];
// const initialEdges = [{ id: 'e1-2', source: '1', target: '5' },{ id: 'e1-3', source: '2', target: '5' },{ id: 'e1-4', source: '5', target: '3' },{ id: 'e2-5', source: '5', target: '4' } ];

/**
 * Tree component renders a flow diagram using ReactFlow based on the data from TableContext.
 * It categorizes nodes into upstream and downstream and positions them accordingly.
 * It also provides a popup view for an expanded diagram.
 *
 * @component
 * @returns {JSX.Element} The rendered Tree component.
 *
 * @example
 * <Tree />
 *
 * @typedef {Object} Node
 * @property {string} id - The unique identifier for the node.
 * @property {Object} position - The x and y coordinates of the node.
 * @property {Object} data - The data associated with the node.
 * @property {string} data.label - The label to display on the node.
 * @property {Object} [style] - Optional styles for the node.
 *
 * @typedef {Object} Edge
 * @property {string} id - The unique identifier for the edge.
 * @property {string} source - The id of the source node.
 * @property {string} target - The id of the target node.
 * @property {Object} markerEnd - The marker at the end of the edge.
 * @property {string} markerEnd.type - The type of marker.
 * @property {string} markerEnd.fill - The fill color of the marker.
 * @property {number} markerEnd.strokeWidth - The stroke width of the marker.
 * @property {Object} style - The style of the edge.
 * @property {string} style.stroke - The stroke color of the edge.
 *
 * @typedef {Object} TableContextType
 * @property {Array} table5 - The data array containing node information.
 * @property {string} applicationName - The name of the application to display in the central node.
 *
 * @property {React.MutableRefObject} reactFlowRef - Reference to the ReactFlow component.
 * @property {boolean} isPopupOpen - State to manage the visibility of the popup.
 * @property {Function} setIsPopupOpen - Function to set the state of isPopupOpen.
 *
 * @function handleOpenPopup - Opens the popup by setting isPopupOpen to true.
 *
 * @useEffect - Hides elements with rel="noopener noreferrer" and attempts to fit the view.
 */
export default function Tree() {
  const reactFlowRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { table5, applicationName } = useContext(TableContext);
  const initialNodes = [];
  const initialEdges = [];

  // Initialize variables to hold Y positions of upstream and downstream nodes
  let upstreamY = 0;
  let downstreamY = 0;
  let upstreamCount = 0;
  let downstreamCount = 0;

  table5.forEach((values, index) => {
    const label = values[1];

    if (label === "downstream") {
      downstreamY += index;
      downstreamCount++;
    } else {
      upstreamY += index;
      upstreamCount++;
    }
  });

  const centralNodeY = 300;
  const centralNodeId = "0";
  let downstreamindex = 0;
  let upstreamindex = 0;
  let maxXPosition = 0;

  // Define a spacing factor to ensure nodes do not overlap
  const spacingFactor = 200;

  // Calculate the starting X positions for upstream and downstream nodes
  const downstreamStartX = -((downstreamCount - 1) * spacingFactor) / 2;
  const upstreamStartX = -((upstreamCount - 1) * spacingFactor) / 2;

  table5.forEach((values, index) => {
    const label = values[1];
    let positionX;

    if (label === "downstream") {
      positionX = downstreamStartX + downstreamindex * spacingFactor;
      console.log("positionX", positionX);
      downstreamindex++;
      const position = {
        x: positionX,
        y: 500,
      };
      const id = `${index + 1}`;
      initialNodes.push({ id, position, data: { label: values[2] } });
      // console.log(`Node pushed: id=${id}, label=${label}, position=${JSON.stringify(position)}, values=${JSON.stringify(values[2])}`);
    }

    if (label === "upstream") {
      positionX = upstreamStartX + upstreamindex * spacingFactor;
      console.log("positionX", positionX);

      upstreamindex++;
      const position = {
        x: positionX,
        y: 100,
      };
      const id = `${index + 1}`;
      initialNodes.push({ id, position, data: { label: values[2] } });
       // ['A', 'B', 'C'].forEach((suffix, idx) => {
      //   console.log("Suffix:", idx)
      //   const newNodeId = `${id}${suffix}`;
      //   const newYPosition = idx=="1"? position.y - 200: position.y - 100;
      //   const newXposition = position.x + (50 * (idx - 1)) - 50;
      //   initialNodes.push({
      //     id: newNodeId,
      //     position: { x: positionX + (130 * (idx - 1)), y: newYPosition },
      //     data: { label: `${suffix}` },
      //   });
    
      //   // Add edges from the main node to the additional nodes
      //   initialEdges.push({
      //     id: `e${id}-${newNodeId}`,
      //     source: newNodeId,
      //     target: id,
      //     markerEnd: {
      //       type: MarkerType.ArrowClosed,
      //       fill: "#000000",
      //       strokeWidth: 4,
      //     },
      //     style: { stroke: "#A9A9A9" }, // Use a neutral color for these edges
      //   });
      // });
    }

    if (Math.abs(positionX) > maxXPosition) {
      maxXPosition = Math.abs(positionX);
    }

    const id = `${index + 1}`;

    initialEdges.push({
      id: `e0-${id}`,
      source: label === "downstream" ? centralNodeId : id,
      target: label === "downstream" ? id : centralNodeId,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        fill: "#000000",
        strokeWidth: 4,
      },
      style: { stroke: label === "downstream" ? "#0073AB" : "#B5E0F5" },
    });
  });

  initialNodes.push({
    id: centralNodeId,
    position: { x: 0, y: centralNodeY },
    data: { label: applicationName },
    style: { fontWeight: "bold" },
  });
  console.log(`Node pushed: id=${centralNodeId}, label=Centralnode, position=${JSON.stringify({ x: 0, y: centralNodeY })}, values=${JSON.stringify(applicationName)}`);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  useEffect(() => {
    document
      .querySelectorAll('[rel="noopener noreferrer"]')
      ?.forEach(function (element) {
        element.style.display = "none";
        console.log("Tree table5", table5);
        var fit = document.getElementsByClassName(
          "react-flow__controls-button react-flow__controls-fitview"
        );
        if (fit) {
          console.log("fit", fit);
        }
        if (reactFlowRef.current) {
        }
      });
  }, []);

  return (
    <>
      {table5?.length > 0 && (
        <div style={{ width: "100%", height: "400px", background: "#F5F5F5" }}>
         <span>
          <AiOutlineExpandAlt
            title="Expand"
            size={25}
            style={{
              float: "right",
              margin: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleOpenPopup();
            }}
          />
           </span>

          <ReactFlow
            style={{
              background: "#F5F5F5",
            }}
            fitView
            nodes={initialNodes}
            edges={initialEdges}
            ref={reactFlowRef}
          >
            <Background color="#F5F5F5" />
            <Controls showInteractive={false} />
          </ReactFlow>
          {isPopupOpen && (
            <Popup onClose={() => setIsPopupOpen(false)}>
              <ReactFlow
                fitView
                nodes={initialNodes}
                edges={initialEdges}
                ref={reactFlowRef}
                style={{ background: "#F5F5F5", padding: "0px", margin: "0px" }}
              >
                <Background color="#F5F5F5" />
                <Controls showInteractive={false} />
              </ReactFlow>
            </Popup>
          )}
        </div>
      )}
    </>
  );
}