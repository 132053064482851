import React from 'react'
import { TableContext } from "../context";
import { useState, useEffect, useContext } from "react";


export default function Error404() {
  const {table1,setTable1}=useContext(TableContext)

  return (
    <div>Error404
      <div>
        {table1}
      </div>
    </div>
  
  )
}
