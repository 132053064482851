import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
const StyledTableContainer = styled(TableContainer)({
  maxHeight: "400px",
  width:"100%", // Set the desired height
  overflowY: "auto",
  marginTop: "10px", // Enable vertical scrolling
});

const StyledTableHead = styled(TableHead)({
  position: "sticky",
  top: 0,
  backgroundColor: "#fff", // Ensure the header has a background color
  zIndex: 1, // Ensure the header stays above the table body
});
/**
 * StatusTable component renders a table displaying the status of various applications.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.rows - An object containing the rows data where each key is a string in the format "ApplicationName:::ServerName" and the value is an object with a `message` property.
 *
 * @returns {JSX.Element} The rendered StatusTable component.
 */
function StatusTable({ rows,key,applicationName }) {
  console.log(rows);
  return (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: "100px" }} aria-label="simple table">
        <StyledTableHead>
          <TableRow>
            <TableCell>Application Name</TableCell>
            <TableCell align="right">Server Name</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
                   {rows && typeof rows === "object" ? (
            Object.keys(rows).map((key) => {
              if (key.includes("Bulk Upload")) {
                const [firstPart, secondPart] = key.split(":::");
                return (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {firstPart}
                    </TableCell>
                    <TableCell>{secondPart}</TableCell>
                    <TableCell align="right" style={{ color: rows[key]?.message?.toLowerCase().includes("exception") ? "red" : "inherit" }}>
                      {rows[key]?.message || "No message available"}
                    </TableCell>
                  </TableRow>
                );
              } else {
                return (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {applicationName}
                    </TableCell>
                    <TableCell>{key}</TableCell>
                    <TableCell align="right" style={{ color: rows[key]?.message?.toLowerCase().includes("exception") ? "red" : "inherit" }}>
                      {rows[key] || "No message available"}
                    </TableCell>
                  </TableRow>
                );
              }
            })
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

export default StatusTable;
