import React from 'react';
 
/**
 * CustomProfilePicture component renders a circular profile picture with given initials.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.initials - The initials to display inside the profile picture.
 * @param {number} props.size - The size (width and height) of the profile picture in pixels.
 * @returns {JSX.Element} A JSX element representing the profile picture.
 */
const CustomProfilePicture = ({ initials, size }) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        background: '#007bff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff',
        fontSize: size / 2,
      }}
    >
      {initials}
    </div>
  );
};
 
export default CustomProfilePicture;
 