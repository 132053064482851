import { TableContext } from "../context";
import React from "react";
import "../App.css";
import DeleteConfirmation from "./DeleteConfirmation";
import {
  useRef,
  useLayoutEffect,
  useContext,
  useState,
  useEffect,
} from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@anchor/react-components";
import { Tooltip } from "react-tooltip";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { IoMdDownload } from "react-icons/io";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; 

/**
 * Table4 component renders a table with filtering, sorting, and download functionalities.
 * It also handles delete requests and updates the UI accordingly.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Array} props.table5 - The data for the table.
 * @param {Function} props.setTable5 - Function to set the table data.
 * @param {Array} props.deletedRows - The rows that have been deleted.
 * @param {Function} props.setDeletedRows - Function to set the deleted rows.
 * @param {Object} props.graphData - Data related to the graph.
 * @param {Array} props.appData - Application data.
 * @param {number} props.tableRightBoundary - The right boundary of the table.
 * @param {Function} props.setTableRightBoundary - Function to set the right boundary of the table.
 * @param {number} props.tableBottomtBoundary - The bottom boundary of the table.
 * @param {Function} props.setTableBottomBoundary - Function to set the bottom boundary of the table.
 * @param {Function} props.fetchWithTimeout - Function to fetch data with a timeout.
 * @param {string} props.FilterIndex - The index used for filtering.
 * @param {string} props.applicationName - The name of the application.
 * @param {string} props.applicationId - The ID of the application.
 * @param {string} props.applicationOwner - The owner of the application.
 * @param {string} props.Header1 - The header for the first column.
 * @param {string} props.Header2 - The header for the second column.
 * @param {string} props.Header3 - The header for the third column.
 * @param {string} props.Header4 - The header for the fourth column.
 * @param {Function} props.setshowCommit - Function to show commit changes.
 * 
 * @returns {JSX.Element} The rendered Table4 component.
 */
export default function Table4(props) {
  const {
    table5,
    setTable5,
    deletedRows,
    setDeletedRows,
    graphData,
    appData,
    tableRightBoundary,
    setTableRightBoundary,
    tableBottomtBoundary,
    setTableBottomBoundary,
    fetchWithTimeout,
  } = useContext(TableContext);
  const heightRef = useRef();
  const [rowCount, setRowCount] = useState(0);
  const tableBodyRef = useRef(null);
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
  let uniqueValuesSet;
  if (Array?.isArray(table5)) {
    uniqueValuesSet = new Set([
      ...table5.map((row) => row[props.FilterIndex]),
      ...appData
        .filter((innerList) => innerList[4] === "create")
        .map((row) => row[1]),
    ]);
  }
  const uniqueValues = Array?.from(uniqueValuesSet); //   console.log(uniqueValues)

  const [sortState5, setSortState5] = useState({ columnIndex: null, order: null });
  const handleSort = (colIndex, direction) => {
    setSortState5({
      columnIndex: colIndex,
      order: direction,
    });
  
    const tbody = document.querySelector('tbody');
    const rows = Array.from(tbody.querySelectorAll('tr'));
  
    const sortedRows = rows.sort((a, b) => {
      const valueA = a.children[colIndex].innerText.toLowerCase();
      const valueB = b.children[colIndex].innerText.toLowerCase();
  
      // Handle sorting for numerical strings, mixed-case strings, or other complex formats
      // If both values are numbers, sort numerically
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);
      
      if (!isNaN(numA) && !isNaN(numB)) {
        return direction === "asc" ? numA - numB : numB - numA;
      }
  
      // String comparison (case-insensitive alphabetical sorting)
      if (valueA < valueB) return direction === "asc" ? -1 : 1;
      if (valueA > valueB) return direction === "asc" ? 1 : -1;
      return 0; // if values are equal
    });
  
    // Clear the existing rows
    while (tbody.firstChild) {
      tbody.removeChild(tbody.firstChild);
    }
  
    // Append the sorted rows
    sortedRows.forEach(row => tbody.appendChild(row));
  };

  /**
   * Handles the change event for filter radio buttons and updates the table rows based on the selected filter.
   * 
   * - Attaches event listeners to radio buttons to filter table rows.
   * - Filters rows based on the selected radio button value.
   * - Updates the display of table rows to show/hide based on the filter criteria.
   * - Calculates and updates the count of visible rows after filtering.
   * 
   * @function handleFilterChange
   * @param {Object} props - The properties object.
   * @param {string} props.applicationName - The name of the application.
   * @param {number} props.FilterIndex - The index of the column to filter.
   * @param {Array} appData - The application data array.
   * @param {React.MutableRefObject} tableBodyRef - The reference to the table body element.
   * @param {Function} setRowCount - The function to update the row count state.
   */
  const handleFilterChange = () => {
    const filterOptions = document?.querySelectorAll('input[type="radio"]');
    const table = document?.getElementById("data-table");
    const tbody = table?.querySelector("tbody");
    const { applicationName } = props;
    // Attach event listener to radio buttons
    filterOptions.forEach((radio) => {
      radio.addEventListener("change", () => {
        const selectedValue = radio.value;
        console.log(document.getElementById("table").style);
        // Loop through table rows and hide/show based on the selected radio button
        const rows = tbody?.querySelectorAll("tr");
        rows?.forEach((row) => {
          const genderCell = row?.querySelector(
            `td:nth-child(${props.FilterIndex + 1})`
          );
          if (
            selectedValue === "All" ||
            genderCell.textContent === selectedValue
          ) {
            row.style.display = "";
          } else {
            row.style.display = "none";
          }
        });
        if (tableBodyRef.current) {
          const visibleRows = Array.from(tableBodyRef.current.getElementsByTagName("tr")).filter(
            (row) => window.getComputedStyle(row).display !== "none"
          );
          const filteredRows = visibleRows.filter((row) => {
            const row1 = Array.from(row.getElementsByTagName("td")).map(td => td.textContent);
            const elementAtIndex2Table1 = row1[2];
            return !appData.some(
              (rowData) => rowData[2] === elementAtIndex2Table1
            );
          });
          setRowCount(filteredRows.length-1);
        }
        // document.getElementById('filter').style.height = document.defaultView.getComputedStyle(document.getElementById('data-table'), "").getPropertyValue("height");
      });
    });
    // Function htmlFor sorting the table columns (you may already have this function)
    function sortTable(columnIndex) {
      // Your sorting logic here
    }
  };
  function indexOf2d(array2d, itemtofind) {
    console.log(
      [].concat.apply([], [].concat.apply([], array2d)).indexOf(itemtofind) !==
        -1
    );
    return (
      [].concat.apply([], [].concat.apply([], array2d)).indexOf(itemtofind) !==
      -1
    );
  }

  /**
   * Handles the download button click event to generate and download a CSV file.
   * 
   * This function uses the `Papa.unparse` method to convert the table data into a CSV format.
   * It then creates a Blob from the CSV data and triggers a download using the `saveAs` function.
   * 
   * @function handleDownloadClick
   * @returns {void}
   */
  const handleDownloadClick = () => {
    const headers = [
      props.Header1,
      props.Header2,
      props.Header3,
      props.Header4,
    ];
    // Assuming table1 is available in the scope and headers are passed as props
    const csv = Papa.unparse({
      fields: headers,
      data: table5,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "App to App.csv");
  };

  /**
   * Handles the deletion of a specified value by making an asynchronous POST request to the server.
   * Displays appropriate toast notifications based on the server response or errors encountered.
   *
   * @param {any} value - The value to be deleted.
   * @returns {Promise<void>} - A promise that resolves when the deletion process is complete.
   * @throws {Error} - Throws an error if the request times out or if there is a server error.
   */
  const handleDelete = async (value) => {
    try {
      const response = await fetchWithTimeout("https://prod-cmdbbackend.azurewebsites.net/delete1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          value: value,
          requested_by: graphData.displayName,
        }),
      });
      const data = await response.json();
      if (data?.message.includes("Request for delete relation")) {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (data?.message.includes("Restricted:")){
        toast.error(data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setDeletedRows([...deletedRows, value]);
        console.log(deletedRows);
        props.setshowCommit(true);
        if (response["status"] == 200) {
          toast.success("Delete request is raised", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          toast.info("You can confirm your changes", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Delete request is failed", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  // useLayoutEffect(() => {
  //   document.getElementById('filter').style.height = heightRef.current?.offsetHeight;
  // }, [heightRef.current?.offsetHeight])
  const updateButtonPosition = () => {
    const tableContainer = document.getElementById("data-table");
    if (tableContainer) {
      var newTableRightBoundary =
        tableContainer.getBoundingClientRect().left +
        tableContainer.offsetWidth;
      var newTableBottomBoundary =
        tableContainer.getBoundingClientRect().bottom +
        tableContainer.offsetHeight;
      // if(document.documentElement.scrollWidth < window.innerWidth){
      //   // newTableRightBoundary+=(window.innerWidth-document.documentElement.scrollWidth);
      //  var a= window.innerWidth-document.documentElement.scrollWidth
      //   newTableRightBoundary-=a;
      //   console.log(typeof(a))
      //   console.log(typeof(newTableRightBoundary))
      // }
      setTableRightBoundary(newTableRightBoundary);
      setTableBottomBoundary(newTableBottomBoundary);
    }
  };
  useLayoutEffect(() => {
    updateButtonPosition();
  }, [table5]);
  useLayoutEffect(() => {
    // Initial update
    // updateButtonPosition();
    // getDimensions()
    // setTimeout(() => {
    //   getToken()
    // }, 2000);
    // getToken()
    const handleResize = () => {
      // setWindowWidth(window.innerWidth);
      // getDimensions()
      updateButtonPosition();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const tbody = document.querySelector("tbody");
    if (tbody) {
      setHasVerticalScrollbar(tbody.scrollHeight > tbody.clientHeight);
    }
  }, [appData]);

  useEffect(() => {
    const tbody = document.querySelector("tbody");
    const downloadButton = document.getElementById("download");
    if (tbody?.scrollHeight > tbody?.clientHeight) {
      if (downloadButton) downloadButton.style.width = "5.2vw";
    } else {
      if (downloadButton) downloadButton.style.width = "4vw";
    }
    if (tableBodyRef.current) {
      const visibleRows = Array.from(tableBodyRef.current.getElementsByTagName("tr")).filter(
        (row) => window.getComputedStyle(row).display !== "none"
      );
      const filteredRows = visibleRows.filter((row) => {
        const row1 = Array.from(row.getElementsByTagName("td")).map(td => td.textContent);
        const elementAtIndex2Table1 = row1[2];
        return !appData.some(
          (rowData) => rowData[2] === elementAtIndex2Table1
        );
      });
      setRowCount(filteredRows.length-1);
    }
  }, [table5, appData]);

  return (
    <div>
       <div
        style={{
          margin: "auto",
          width: "90%",
          // marginTop: "70px",

         
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          style={{
            border: "none",
            borderRadius: "5px",
            padding: "6px",
            backgroundColor: "rgb(66, 176, 213)",
            color: "white",
            marginBottom: "5px",
            
          }}
          onClick={handleDownloadClick}
        >
          Extract 
          <IoMdDownload
            size={20}
            style={{
              marginLeft: "5px",
              
            }}
          />
        </button>
      </div>
      <div
        ref={heightRef}
        style={{
          margin: "auto",
          width: "90%",
          // marginTop: "70px",
          borderBottom: "2px solid #87CEEB",
          borderLeft: "2px solid #87CEEB",
          maxWidth: "fit-content",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderRight: "2px solid #87CEEB",
            borderTop: "2px solid #87CEEB",
            fontSize: "16px",
          }}
        >
          <div style={{ alignSelf: "flex-start", margin: "5px" }}>
            Application ID: {props.applicationId}
          </div>
          <div style={{ alignSelf: "flex-center", margin: "5px" }}>
            Application Manager: {props.applicationOwner}
          </div>
          <div style={{ alignSelf: "flex-end", margin: "5px" }}>
            No. of certified applications :{" "}
            {
              // table5.filter((row1) => {
              //   // Assuming row1 is an array and data is a 2D array
              //   const elementAtIndex2table5 = row1[2];
              //   return !appData.some(
              //     (rowData) => rowData[2] === elementAtIndex2table5
              //   );
              // }).length
              rowCount
            }
          </div>
        </div>
        <div
          id="filter"
          style={{
            float: "left",
            border: "2px solid #87CEEB",
            borderBottom: "none",
            borderLeft: "none",
            width: "206px", // height:heightRef.current?.offsetHeight,
            // minHeight: "200px",
            maxHeight: "350px",
            paddingTop: "7px",
            borderRight: "none",
          }}
        >
          <div style={{ borderBottom: "2px solid #87CEEB", height: "42px" }}>
            <label
              htmlFor="ciTypeFilter"
              style={{ margin: "4px", fontSize: "16px" }}
            >
              Filter By Relationship Type:
            </label>
          </div>
          <div className="filters" style={{ padding: "7px" }}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="All"
                name="filter"
                id="All"
              />
              <label
                className="form-check-label"
                htmlFor="All"
                style={{ fontSize: "16px" }}
              >
                All
              </label>
            </div>
            {uniqueValues.map((value, key) => {
              return (
                <div className="form-check" key={key}>
                  <input
                    className="form-check-input"
                    type="radio"
                    value={value}
                    id={value}
                    name="filter"
                    onChange={handleFilterChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={value}
                    style={{ fontSize: "16px" }}
                  >
                    {value}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div
          id="table"
          className="table-container"
          ref={heightRef}
          // style={{
          //   marginLeft: "20px",
          //   overflowY: "auto",
          //   tableLayout: "fixed",
          //   borderCollapse: "collapse",
          //   minHeight: "200px",
          //   maxHeight: "350px",
          //   maxWidth: "fit-content",
          //   // display:"table-cell"
          // }}
        >
          <table id="data-table" className="custom-table" ref={tableBodyRef}>
            <thead>
              <tr>
                {[props.Header1, props.Header2, props.Header3, props.Header4].map((header, index) => (
                    <th key={index} style={{ border: "2px solid #87CEEB" }}>
                      <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {header}
                          <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                            {sortState5.columnIndex === index && sortState5.order === "asc"  ? (
                              <KeyboardArrowDownIcon
                                style={{
                                  cursor: "pointer",
                                  fontSize: "24px",
                                  fontWeight: "bold", 
                                }}
                                onClick={() => handleSort(index, "desc")}
                              />
                            ) : ( sortState5.columnIndex !== index && 
                              <KeyboardArrowUpIcon
                                style={{
                                  cursor: "pointer",
                                  fontSize: "16px",
                                  fontWeight: "bold", 
                                }}
                                onClick={() => handleSort(index, "asc")}
                              />
                            )}
                            {sortState5.columnIndex === index && sortState5.order === "desc" ? (
                              <KeyboardArrowUpIcon
                                style={{
                                  cursor: "pointer",
                                  fontSize: "24px",
                                  fontWeight: "bold", 
                                }}
                                onClick={() => handleSort(index, "asc")}
                              />
                            ) : ( sortState5.columnIndex !== index &&
                              <KeyboardArrowDownIcon
                                style={{
                                  cursor: "pointer",
                                  fontSize: "16px", 
                                  fontWeight: "bold",
                                }}
                                onClick={() => handleSort(index, "desc")}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </th>
                  )
                )}
                <th
                  id="download"
                  style={{ border: "2px solid #87CEEB", width: "5.2vw",transition:"none" }}
                >
                 {" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {appData
                .filter((innerList) => innerList[4] === "create")
                .map((value, key) => {
                  return (
                    <tr
                      key={key}
                      id={key}
                      style={{ background: "rgb(178, 233, 205)" }}
                    >
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[0]}
                        <span
                          style={{
                            marginLeft: "10px",
                            display: "inline-block",
                            position: "relative",
                            top: "6px",
                          }}
                          data-tooltip-id="create-info"
                          data-tooltip-content={
                            "Create requested by " +
                            value[6] +
                            " at " +
                            value[5] +
                            " GMT"
                          }
                          data-tooltip-place="bottom-start"
                        >
                          <Icon name="info-circle" size="24" />
                        </span>
                        <Tooltip id="create-info" />
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[1]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[2]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[3]}
                      </td>
                      <td
                        style={{
                          border: "2px solid #87CEEB",
                          textAlign: "center",
                          // width: hasVerticalScrollbar ? "104px" : "127px",
                        }}
                      ></td>
                    </tr>
                  );
                })}
              {table5.map((value, key) => {
                return (
                  <tr
                    key={key}
                    id={key}
                    style={{
                      background: appData.some((subArray) =>
                        subArray.includes(value[4])
                      )
                        ? "rgba(233, 178, 183, 1)"
                        : "none",
                      textDecoration: appData.some((subArray) =>
                        subArray.includes(value[4])
                      )
                        ? "line-through"
                        : "none",
                    }}
                  >
                    <td style={{ border: "2px solid #87CEEB" }}>
                      {value[0]}
                      <span
                        style={{
                          marginLeft: "10px",
                          display: appData.some((subArray) =>
                            subArray.includes(value[4])
                          )
                            ? "inline-block"
                            : "none",
                          position: "relative",
                          top: "6px",
                        }}
                        data-tooltip-id="delete-info"
                        data-tooltip-content={appData
                          .filter((innerList) => innerList.includes(value[4]))
                          .map((item, key) => {
                            return (
                              "Delete requested by " +
                              item[6] +
                              " at " +
                              item[5] +
                              " GMT"
                            );
                          })}
                        data-tooltip-place="bottom-start"
                      >
                        <Icon style={{}} name="info-circle" size="24" />
                      </span>
                      <Tooltip id="delete-info" />
                    </td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[1]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[2]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[3]}</td>
                    <td
                      style={{
                        border: "2px solid #87CEEB",
                        textAlign: "center",
                        // width: hasVerticalScrollbar ? "104px" : "127px"
                      }}
                    >
                      <DeleteConfirmation
                        onDelete={() => {
                          handleDelete(value);
                        }}
                        value={value}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
