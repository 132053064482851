import React from 'react';
import './Spinner.css'; // Import CSS for styling
 
/**
 * Spinner component renders a loading spinner.
 *
 * @component
 * @example
 * return (
 *   <Spinner />
 * )
 */
const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};
 
export default Spinner;