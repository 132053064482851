import React, { useState, useEffect, useContext } from "react";
import "../App.css"; // Assuming you have a CSS file for styles
import { TableContext } from "../context";
import StatusTable from "./StatusTable";
import { toast } from "react-toastify";

const ServerEnvironmentEditor = ({
  servers,
  setServers,
  onClose,
  applicationName,
  applicationenv,
}) => {
  console.log(applicationName);
  const [environments, setEnvironments] = useState(servers);
  const [showstatus, setshowstatus] = useState(false);
  const [response, setResponse] = useState({});
  const { graphData, fetchWithTimeout, setshowCommit } =
    useContext(TableContext);

  const handleSelectChange = async (server, value) => {
    console.log("Selected Server:", server);
    console.log("Selected Environment:", value);
    setEnvironments((prevEnvironments) => ({
      ...prevEnvironments,
      [server]: value,
    }));
    setServers((prevServers) => ({
      ...prevServers,
      [server]: value,
    }));
    await console.log(servers);
  };

  useEffect(() => {
    setEnvironments(servers);
  }, [servers]);

  const handleEdit = async (e) => {
    try {
      const response = await fetchWithTimeout(
        `https://prod-cmdbbackend.azurewebsites.net/updateserverenv`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            app_name: applicationName,
            server_dict: servers,
            requested_by: graphData.displayName,
          }),
        }
      );
      console.log("env change response", response);
      const data = await response.json();
      setResponse(data);
      setshowstatus(true);
      const hasSuccess = Object.values(data).some(
        (status) => status === "Record inserted successfully."
      );
      if (hasSuccess) {
        console.log("Success");
        setshowCommit(true);
        toast.info(
          "Request completed successfully.\nYou can confirm your changes",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } catch (error) {
      console.error("Error updating server environments:", error);
    }
    console.log("Edited Environments:", environments);
    console.log("Servers:", servers);
  };

  const handleDiscard = () => {
    setEnvironments(servers);
    console.log("Changes discarded.");
    onClose();
  };

  return !showstatus ? (
    <div className="container">
      <span
        style={{
          marginTop: "10px",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Below servers are not currently associated with an environment.
        <br /> Kindly ensure that these environments are updated before
        establishing any relationships.
      </span>
      <div className="server-list">
        {Object.keys(environments).map((server) => (
          <div key={server} className="server-row">
            <span>{server}</span>
            <select
              className="custom-select"
              value={environments[server] || ""}
              onChange={(e) => handleSelectChange(server, e.target.value)}
            >
              <option value="">Select environment</option>
              <option
                value="Production"
                disabled={
                  !applicationenv[applicationName]?.includes("Production")
                }
              >
                Production
              </option>
              <option
                value="Development"
                disabled={applicationenv[applicationName]?.includes(
                  "Production"
                )}
              >
                Development
              </option>
              <option
                value="Test"
                disabled={applicationenv[applicationName]?.includes(
                  "Production"
                )}
              >
                Test
              </option>
              <option
                value="Pre Production"
                disabled={applicationenv[applicationName]?.includes(
                  "Production"
                )}
              >
                Pre Production
              </option>
            </select>
          </div>
        ))}
      </div>
      <strong style={{ marginBottom: "20px" }}>
        Note: Any request to modify the environment will undergo the same
        approval process as required for creating relationships. The server
        environment value should be aligned with the corresponding application's
        CI environment.
      </strong>
      <div className="action-buttons">
        <button
          onClick={async (e) =>
            await toast.promise(handleEdit(e), {
              pending: "Processing your request...",
              error: "Error updating server environments.",
            })
          }
          className="edit-button"
        >
          Save
        </button>
        <button onClick={handleDiscard} className="discard-button">
          Discard
        </button>
      </div>
    </div>
  ) : (
    <StatusTable
      rows={response}
      key="Environment Edit"
      applicationName={applicationName}
    />
  );
};

export default ServerEnvironmentEditor;
